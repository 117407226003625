import { SupportedLocaleData } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { zepAnalytics } from '@zep/utils/analytics';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

export const Cta = () => {
  return (
    <section
      className={cn(
        'flex flex-col items-center bg-gradient-to-b from-white to-violet-100',
        'pt-2xl px-[16px] min-[751px]:pt-3xl min-[751px]:px-[24px] min-[1025px]:px-[40px]',
      )}>
      <Title />
      <Buttons />
      <Video />
    </section>
  );
};

const Title = () => {
  const { t } = useTranslation();

  return (
    <h1 className="flex flex-col items-center text-center text-display-md-extrabold">
      <p>{t('landing.cta.firstTitle', '클릭 몇 번으로')}</p>
      <p>
        <Trans
          t={t}
          i18nKey="landing.cta.secondTitle"
          components={{
            Comp: (
              <StrongText text={t('landing.title.highlight', '방탈출 퀴즈')} />
            ),
            Space: <br className="min-[751px]:hidden" />,
          }}
        />
      </p>
    </h1>
  );
};

export const StrongText = ({ text }: { text: string }) => (
  <strong className="text-primary">{text}</strong>
);

export const INQUIRY_LINK: SupportedLocaleData<'ko' | 'en' | 'ja'> = {
  ko: 'https://quiz.zep.us/embed/sales?webFormUrl=https%3A%2F%2Fsalesmap.kr%2Fweb-form%2Fa399a0d0-ade8-48be-840f-89b7a88f03f2&title=ZEP+QUIZ+%EB%8F%84%EC%9E%85%EC%9D%B4+%EA%B3%A0%EB%AF%BC%EC%9D%B4%EC%8B%A0%EA%B0%80%EC%9A%94%3F%0A%EC%84%A0%EC%83%9D%EB%8B%98%EC%9D%84+%EC%9C%84%ED%95%9C+%EC%B5%9C%EC%A0%81%EC%9D%98+%EC%86%94%EB%A3%A8%EC%85%98%EC%9D%84+%EC%A0%9C%EA%B3%B5%ED%95%B4+%EB%93%9C%EB%A0%A4%EC%9A%94&desc=%EC%95%84%EB%9E%98+%EC%96%91%EC%8B%9D%EC%9D%84+%EC%9E%91%EC%84%B1+%ED%9B%84+%EC%A0%9C%EC%B6%9C%ED%95%B4+%EC%A3%BC%EC%8B%9C%EB%A9%B4%0A%EB%8B%B4%EB%8B%B9+%EB%A7%A4%EB%8B%88%EC%A0%80%EA%B0%80+%EB%B9%A0%EB%A5%B8+%EC%8B%9C%EC%9D%BC+%EB%82%B4%EB%A1%9C+%EC%83%81%EB%8B%B4%EC%9D%84+%EB%8F%84%EC%99%80%EB%93%9C%EB%A6%AC%EA%B2%A0%EC%8A%B5%EB%8B%88%EB%8B%A4.',
  en: 'https://tally.so/r/wvLX7X',
  ja: 'https://tally.so/r/npWejy',
};

const Buttons = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  return (
    <div className="mb-2xl mt-[30px] flex items-center gap-2xs">
      <Button
        variant="outlined"
        intent="alternative"
        size="lg"
        rounded="default"
        asChild={true}>
        <Link
          href={INQUIRY_LINK[locale] ?? INQUIRY_LINK.en}
          target="_blank"
          rel="noopener noreferrer">
          {t('home.pricing.IntroduceInquiry', '도입 문의')}
        </Link>
      </Button>
      <Button
        variant="contained"
        intent="strong"
        size="lg"
        rounded="default"
        asChild={true}>
        <Link
          href={'/build'}
          onClick={() =>
            zepAnalytics.track('enter_create', {
              location: 'home',
            })
          }
          prefetch={false}>
          {t('landing.cta.createQuiz')}
        </Link>
      </Button>
    </div>
  );
};

const VIDEO_LINKS: {
  webm: SupportedLocaleData<'ko' | 'en' | 'ja'>;
  mp4: SupportedLocaleData<'ko' | 'en' | 'ja'>;
} = {
  webm: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/EfYNn3wK87cudXoAHwEbXPj/kDRvy3wK87h9d357d4b149f890789bef39bb4a72f27udXsAydz1WjZ/zep_quiz_landing.webm',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/PXx4H3wK87dudXoAHxsE5ka/Dyj7X3wK87h219088316eebd30c1f2c79aa4de61df5udXz1BCRypCh/zep_quiz_landing_en.webm',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/s1wwr3wK87cudXoAHBQNrxN/5nVkf3wK87h426519772c00bb15dca18613d6d466f7udXyXjBjebqv/zep_quiz_landing_ja.webm',
  },
  mp4: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/RLha63wK87cudXS0Sj3w5ne/TO2ts3wK8aZb1e317e217103cf53f1b21c143ab0439udXS0U7M67MG/zep_quiz_landing_m.mp4',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/rm0YA3wK87cudXS0Sj2x97m/ghACJ3wK87h916678e26b0b6660baa6c6cae219c196udXS0U83Xsoq/zep_quiz_landing_en_m.mp4',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/qsKVs3wK87dudXS0SibPEtU/NRrOq3wK87hb1e317e217103cf53f1b21c143ab0439udXS0U75w7Wb/zep_quiz_landing_ja_m.mp4',
  },
};

const Video = () => {
  const { locale } = useLocale();

  return (
    <div className="min-[751px]:px-[40px]">
      <video
        className="aspect-[32/15] w-full max-w-[1280px] rounded-lg rounded-b-none object-cover object-top"
        playsInline
        controls={false}
        autoPlay
        muted
        loop>
        <source
          type="video/webm"
          src={VIDEO_LINKS.webm[locale] ?? VIDEO_LINKS.webm.en}
        />
        <source
          type="video/mp4"
          src={VIDEO_LINKS['mp4'][locale] ?? VIDEO_LINKS['mp4'].en}
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
