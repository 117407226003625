import { SupportedLocaleData } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { useTranslation } from 'next-i18next';

export const Teacher = () => {
  const { t } = useTranslation();

  return (
    <section className="px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:px-[40px] min-[1025px]:py-3xl">
      <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-lg min-[751px]:items-stretch min-[751px]:gap-2xl min-[1025px]:gap-3xl">
        <h2 className="text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal min-[751px]:text-left">
          {t('landing.teacherFeatures.title')}
        </h2>

        <div className="grid w-full grid-cols-1 justify-items-center gap-md min-[751px]:grid-cols-2 min-[751px]:gap-xl min-[1025px]:gap-2xl">
          <div className="col-span-1 min-[751px]:col-span-2 min-[751px]:w-full">
            <Function1 />
          </div>
          <Function2 />
          <Function3 />
        </div>
      </div>
    </section>
  );
};

const FUNCTION_1_IMAGE_LINKS: SupportedLocaleData<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/w1rqQ3wK87durTwZw7XwnUz/TEHya3wK87h974181ff6c247f697c7e5ae243699295urTwZzFWPwGO/quiz_landing_1_1_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/DuNOw3wK87durTwZw43qYao/U99Fd3wK87h974181ff6c247f697c7e5ae243699295urTwZzFXgxs8/quiz_landing_1_1_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/pC4zP3wK87curTwZw5pnSRk/whRFm3wK87F8bbdff842495b7a28f1d44cc925e271durTwZycHPgEp/quiz_landing_1_1_image_jp_2x.webp',
};

const Function1 = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  return (
    <article className="border-opacity-black-2 flex w-full max-w-[500px] flex-col overflow-hidden rounded-lg border bg-purple-50 min-[751px]:max-w-none min-[751px]:flex-row">
      <div className="flex-1">
        <div className="flex size-full flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pr-0 min-[1025px]:p-3xl min-[1025px]:pr-0">
          <h3 className="flex flex-col items-center text-heading-lg-extrabold text-purple-800 min-[750px]:text-heading-xl-extrabold min-[751px]:items-stretch">
            <p className="whitespace-normal text-center min-[751px]:whitespace-pre-line min-[751px]:text-left min-[1024px]:whitespace-normal">
              {t('landing.teacherFeature1.title1')}
            </p>
            <p>{t('landing.teacherFeature1.title2')}</p>
          </h3>
          <p className="text-center text-body-lg-bold text-opacity-black-9 min-[751px]:text-left min-[751px]:text-heading-sm-bold">
            {t('landing.teacherFeature1.content')}
          </p>
        </div>
      </div>

      <div className="flex-1 min-[751px]:h-[310px] min-[1025px]:h-[374px]">
        <img
          src={FUNCTION_1_IMAGE_LINKS[locale] ?? FUNCTION_1_IMAGE_LINKS.en}
          alt="Function 1"
          className="size-full min-[751px]:object-cover min-[751px]:object-left"
        />
      </div>
    </article>
  );
};

const FUNCTION_2_IMAGE_LINKS: SupportedLocaleData<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ADOzJ3wK87durTwZw5Yw1kI/LnxVk3wK87h974181ff6c247f697c7e5ae243699295urTwZzFI792b/quiz_landing_1_2_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/QTJ7Q3wK87curTwZw7sGPc5/jj7iH3wK87h974181ff6c247f697c7e5ae243699295urTwZzFybkDe/quiz_landing_1_2_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/C2b6T3wK87curTwZw7A0Kjo/BCxTm3wK87F8bbdff842495b7a28f1d44cc925e271durTwZydnSagd/quiz_landing_1_2_image_jp_2x.webp',
};

const Function2 = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  return (
    <article className="border-opacity-black-2 flex w-full max-w-[500px] flex-col justify-between overflow-hidden rounded-lg border bg-purple-100 min-[751px]:h-[420px] min-[751px]:max-w-none min-[1025px]:h-[480px]">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <h3 className="text-center text-heading-lg-extrabold text-purple-900 min-[751px]:whitespace-normal min-[751px]:text-left min-[751px]:text-heading-xl-extrabold min-[1024px]:whitespace-pre-line">
          {t('landing.teacherFeature2.title')}
        </h3>
        <p className="text-center text-body-lg-bold text-opacity-black-9 min-[751px]:text-left min-[751px]:text-heading-sm-bold">
          {t('landing.teacherFeature2.content')}
        </p>
      </div>

      <img
        src={FUNCTION_2_IMAGE_LINKS[locale] ?? FUNCTION_2_IMAGE_LINKS.en}
        alt="Function 2"
      />
    </article>
  );
};

const Function3 = () => {
  const { t } = useTranslation();

  return (
    <article className="border-opacity-black-2 flex w-full max-w-[500px] flex-col justify-between overflow-hidden rounded-lg border bg-[#F6F3FA] min-[751px]:h-[420px] min-[751px]:max-w-none min-[1025px]:h-[480px]">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <h3 className="text-center text-heading-lg-extrabold text-purple-900 min-[751px]:whitespace-normal min-[751px]:text-left min-[751px]:text-heading-xl-extrabold min-[1024px]:whitespace-pre-line">
          {t('landing.teacherFeature3.title')}
        </h3>
        <p className="text-center text-body-lg-bold text-opacity-black-9 min-[751px]:text-left min-[751px]:text-heading-sm-bold">
          {t('landing.teacherFeature3.content')}
        </p>
      </div>

      <img
        src={
          'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/XHccZ3wK87curTxdqoZM4Ya/1eqNz3wK87hdf4b68769883a6e0508f3ca3bc3eae6durTxdsmZtPHM/quiz_landing_1_3_image_kr_2x.webp'
        }
        alt="Function 3"
      />
    </article>
  );
};
